import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/hoe-werkt-imodium-instant/"> Hoe werkt IMODIUM®? </a>
              </li>
              <li className="active last">
                <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                  De werkzame stof: loperamide
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-11">
            <h1>De werkzame stof van IMODIUM®: loperamide</h1>
            <div className="intro row">
              <div className="three columns">
                <h2>Werkzame stof</h2>
              </div>
              <div className="nine columns">
                <p>
                  <strong>Loperamide</strong>&nbsp;is o.a. verkrijgbaar onder de
                  naam{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>{" "}
                  en wordt gebruikt voor de behandeling van de{" "}
                  <strong>symptomen van diarree</strong>.
                </p>
              </div>
            </div>
            <h4>Wat gebeurt er bij diarree in de darm?</h4>
            <p>
              De meeste mensen kennen de typische symptomen van diarree: te
              frequente en te vloeibare ontlasting. Wat gebeurt er bij{" "}
              <strong>diarree</strong> in je darm?
            </p>
            <p>
              Bij <strong>diarree</strong> hebben we altijd te maken met drie
              veranderingen in de darmfunctie:
            </p>
            <ul>
              <li>
                <strong>Een versterkte darmbeweging</strong>:<br />
                je darm werkt sneller dan normaal, waardoor de darminhoud
                sneller naar de uitgang geleid wordt.
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Verminderde vochtopname in de darm</strong>:<br />
                bij een normale vertering worden in de darm vocht, mineralen en
                voedingsstoffen uit de voeding opgenomen. Door de versterkte
                darmbeweging heeft het lichaam daar echter minder tijd voor.
                Daarom blijft de ontlasting vloeibaar. Aangezien het lichaam nu
                minder vocht en voedingsstoffen krijgt, is er een kans op
                uitdroging (dehydratatie).&nbsp;
                <br />
                &nbsp;
              </li>
              <li>
                <strong>Versterkte afscheiding of secretie</strong>:<br />
                tegelijk geeft het lichaam meer vocht en elektrolyten in je darm
                af. De ontlasting wordt daardoor steeds vloeibaarder en de
                ontlastingsdrang sterker.
              </li>
            </ul>
            <h3>&nbsp;</h3>
            <h3>Loperamide werkt deze functiestoornissen in je darm tegen</h3>
            <p>
              De werkzame stof loperamide werkt de drie hierboven genoemde
              functiestoornissen tegen, zodat de ontlasting weer vaster en
              uitdroging voorkomen wordt.
            </p>
            <p>
              Loperamide zorgt in je darm voor het herstel van de normale
              darmbeweging. Daardoor blijft de voeding langer in je darm en kan
              je lichaam er meer vocht en voedingsstoffen uit opnemen. Daarnaast
              wordt de versterkte afscheiding van vocht en elektrolyten in je
              darm weer genormaliseerd.
            </p>
            <h3>Eigenschappen van loperamide</h3>
            <ul>
              <li>
                <strong>Loperamide</strong> werkt <strong>uitdroging</strong>{" "}
                bij diarree tegen doordat het de opname van vocht en mineralen
                verbetert.
              </li>
              <li>
                <strong>De duur van je diarree</strong> kan met loperamide
                aanzienlijk <strong>verkort</strong> worden.&nbsp;
              </li>
              <li>
                <strong>Loperamide</strong> helpt je lichaam de stoelgang snel
                opnieuw in te dikken en je{" "}
                <strong>natuurlijke evenwicht</strong> te herstellen. Dit
                natuurlijke evenwicht zal ervoor zorgen dat ziekteverwekkende
                kiemen opnieuw minder vat op je darm krijgen.
              </li>
              <li>
                <strong>Loperamide</strong> spant de spier van je anus tijdelijk
                op. Hierdoor{" "}
                <strong>
                  voorkomt loperamide dat je plots stoelgang verliest{" "}
                </strong>
                en worden gênante situaties je bespaard.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
